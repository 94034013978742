.status {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.908);
    color: rgb(0, 0, 0);
    text-decoration: none; 
    padding: 5px 10px;
    border-radius: 12px;
    padding-bottom: 6px;

}

.status:hover{
    background-color: rgb(93, 93, 93);
    cursor: pointer;
    color: white;
 
}
#status-button{
  
    margin-left: 10px;
    color: rgb(255, 255, 255);
    background-color: blue;
    border: none;
    border-radius: 8px;
    width: 80px;
    height: 30px;
}
#status-button:hover{
   cursor: pointer;
   background-color: rgb(23, 220, 23);
 }
