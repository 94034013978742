* {
  margin: 0;
  padding: 0;
}

.admin_login-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(50, 117, 241, 0.477);
  color: rgba(0, 0, 0, 0.766);

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.login-container {
  box-shadow: 1px 10px 25px 5px rgba(0, 0, 0, 0.716);
  text-align: center;
  border: solid 1px rgba(255, 255, 255, 0);
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  height: 180px;
  width: 400px;
}
#admin-login-text {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.card-form {
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.44);
}
