.faq-bg-img{
    max-height: calc(100vh - 80px );
    height: 250px;
    object-fit: cover;
    width: 100%;
    position: relative;
    
}
.faq-img-heading{

     max-height: calc(100vh - 80px );
     height: 250px;
    width: 100%; 
    position: absolute;
    top: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 57%);
    color: white;
}
