.footer{
    width: 100%;
    background-color: #495056;
    color: #FFF;
    padding: 10px 40px;
    font-size: 13px;
}
.footer p{
    margin: 0px;
}
.footer-justify{
    display: flex;
    gap:15px;
    margin-top:3px;
    cursor: pointer;
    justify-content: end;
}
@media screen and (max-width:768px){
   .footer-justify{
    justify-content: space-between;
   }

    }