
.nav-bar-main-div{
    
    text-align: right;
    color: rgb(255, 255, 255);
    border: solid;
    background-color: rgb(72, 72, 72);
    margin-top: -3px;
    margin-left: -3px;
    margin-right: -3px;
    
} 
#img-logo{
 
   padding-right: 850px;
    width: 50px;
    height: 30px;
}
.nav-bar-main-div li{
    
    display: inline-block;
    margin: 0 20px;
    font-size: large;
}
.nav-bar-main-div .active{
    color: rgb(227, 52, 52);
    text-decoration: underline;
}
.nav-bar{
    text-decoration: none;
    color: rgb(255, 255, 255);

}
.apply-visa {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.908);
    color: rgb(0, 0, 0);
    text-decoration: none; 
    padding: 5px 10px;
    border-radius: 12px;
    padding-bottom: 6px;

}

.apply-visa:hover{
    background-color: rgb(93, 93, 93);
    cursor: pointer;
    color: white;
 
}
