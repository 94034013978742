.not-found-mobile{
    display: none;
}
@media screen and (max-width:500px){
    .not-found-desktop{
        display: none;
    }
    .not-found-mobile{
        display: flex;
    }
}